.ui-select {
  &__trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 180px;
    padding: 0.5rem 1rem;
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    color: #374151;
    cursor: pointer;

    &:hover {
      background: #f9fafb;
    }

    &:focus {
      outline: none;
      border-color: #2563eb;
      ring: 2px solid rgba(37, 99, 235, 0.2);
    }
  }

  &__icon {
    width: 1rem;
    height: 1rem;
    color: #6b7280;
    margin-left: 0.5rem;
  }

  &__content {
    overflow: hidden;
    background: white;
    border-radius: 0.5rem;
    border: 1px solid #e5e7eb;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    margin-top: 0.25rem;
    width: var(--radix-select-trigger-width);
  }

  &__item {
    font-size: 0.875rem;
    color: #374151;
    padding: 0.5rem 2rem 0.5rem 1rem;
    position: relative;
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      background: #f3f4f6;
    }

    &[data-highlighted] {
      background: #f3f4f6;
      outline: none;
    }

    &[data-state="checked"] {
      background: #f3f4f6;
    }
  }

  &__item-indicator {
    position: absolute;
    right: 0.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &__check {
    width: 1rem;
    height: 1rem;
    color: #2563eb;
  }
} 