.st-commercial-banner {
  background-color: #f8f9fa;
  
  .st-book-card {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    height: 100%;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
    }
  }

  .st-book-image-wrapper {
    margin-bottom: 20px;
    text-align: center;
  }

  .st-book-image {
    max-width: 200px;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  }

  .st-book-content {
    text-align: center;
  }

  .st-book-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #222222;
  }

  .st-coming-soon {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #5a5a5a;
  }

  .st-book-price {
    font-size: 24px;
    font-weight: 700;
    color: #0066ff;
    margin-bottom: 15px;
  }
}

// Modal Styles
.st-book-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.st-book-modal {
  background: white;
  border-radius: 10px;
  padding: 30px;
  max-width: 800px;
  width: 90%;
  position: relative;
  margin: 20px;
}

.st-modal-close {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  
  &:hover {
    color: #222;
  }
}

.st-modal-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.st-modal-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #222222;
}

.st-modal-price {
  font-size: 28px;
  font-weight: 700;
  color: #0066ff;
  margin-bottom: 20px;
}

.st-modal-description {
  font-size: 16px;
  line-height: 1.6;
  color: #666666;
  margin-bottom: 30px;
}

@media screen and (max-width: 991px) {
  .st-commercial-banner {
    .st-book-card {
      padding: 20px;
    }

    .st-book-title {
      font-size: 18px;
    }

    .st-book-price {
      font-size: 20px;
    }
  }
  
  .st-book-modal {
    padding: 20px;
    
    .row {
      flex-direction: column;
    }
    
    .col-md-4 {
      margin-bottom: 20px;
    }
  }
} 