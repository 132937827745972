.st-cta-sidebar {
  width: 20rem; // w-80
  display: flex;
  flex-direction: column;
  gap: 1rem; // space-y-4

  @media screen and (max-width:700px){
    width:40rem;
    flex-direction: row;
  }

  @media screen and (max-width:500px){
    width:100%;
    flex-direction: column;
  }

  &__card {
    border-radius: 0.5rem; // rounded-lg
    background-color: #2cc7ba;
    padding: 1.5rem; // p-6
    color: white;
  }

  &__title {
    margin-bottom: 1rem; // mb-4
    font-size: 1.125rem; // text-lg
    font-weight: 500; // font-medium
  }

  &__description {
    margin-bottom: 1.5rem; // mb-6
    font-size: 0.875rem; // text-sm
    opacity: 0.9;
  }

  &__button {
    width: 100%; // w-full
  }
} 