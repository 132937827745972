.st-job-list {
  display: flex;
  flex-direction: column;
  gap: 1rem; // space-y-4

  &__card {
    border-radius: 0.5rem; // rounded-lg
    border: 1px solid #e5e7eb; // border
    background-color: white;
    padding: 1.5rem; // p-6
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 1rem; // mb-4
  }

  &__company {
    font-size: 0.875rem; // text-sm
    font-weight: 500; // font-medium
    color: #6b7280; // text-muted-foreground
  }

  &__title {
    font-size: 1.25rem; // text-xl
    font-weight: 600; // font-semibold
  }

  &__meta {
    margin-top: 0.5rem; // mt-2
    display: flex;
    align-items: center;
    gap: 1rem; // gap-4
    font-size: 0.875rem;
    color: #6b7280;
  }

  &__posted {
    font-size: 0.875rem;
    color: #6b7280;
  }

  &__description {
    margin-bottom: 1rem; // mb-4
    font-size: 0.875rem;
    color: #6b7280;

    button {
      margin-left: 0.25rem; // ml-1
      color: #2563eb; // text-primary
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 0.5rem; // gap-2
  }

  &__like-button {
    border-radius: 9999px;
    padding: 0.5rem;
    cursor: pointer;

    &:hover {
      background-color: #f3f4f6;
    }

    svg {
      height: 1.25rem; // h-5
      width: 1.25rem; // w-5
      color: #6b7280; // text-muted-foreground
    }
  }

  &__reward {
    font-size: 0.875rem;
    color: #6b7280;
  }

  &__read-more {
    margin-left: 2px;
    cursor: pointer;
  }

  &__suggest-button {
    color: black;
    border: none;
    background-color: #f3f4f6;
    border-radius: 20px;
  }
} 