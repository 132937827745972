.st-filter-sidebar {
  width: 16rem; // w-64
  
  &__section {
    margin-bottom: 1.5rem; // space-y-6

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    margin-bottom: 1rem; // mb-4
    font-size: 0.875rem; // text-sm
    font-weight: 500; // font-medium
  }

  &__options {
    display: flex;
    flex-direction: column;
    gap: 0.5rem; // space-y-2
  }

  &__option {
    display: flex;
    align-items: center;
    gap: 0.5rem; // space-x-2
  }
} 