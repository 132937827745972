.st-search-header {
  margin-top: 120px;
  border-bottom: 1px solid #e5e7eb;
  // background-color: white;

  &__container {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;

    @media screen and (max-width:700px){
      display: flex;
      flex-direction: column;
    }
  }

  &__search-wrapper {
    position: relative;
    flex: 1;

    @media screen and (max-width:700px) {
      width: 100%;
    }
  }

  &__search-icon {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    height: 1rem;
    width: 1rem;
    transform: translateY(-50%);
    color: #6b7280;
  }

  &__input {
    padding-left: 2.25rem;
    width: 100%;
    @media screen and (max-width:700px) {
      width: 100%;
    }
  }

  &__select {
    width: 180px;
    @media screen and (max-width:700px) {
      width: 100%;
    }
  }

  &__button {
    background-color: #ff7c6b;
    &:hover {
      background-color: rgba(#ff7c6b, 0.9);
    }
    @media screen and (max-width:700px) {
      width: 100%;
    }
  }
} 