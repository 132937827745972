.ui-button {
  border-radius: 0.375rem; // rounded-md
  padding: 0.5rem 1rem; // px-4 py-2
  font-weight: 500; // font-medium

  &--default {
    background-color: var(--color-primary);
    color: white;
  }

  &--secondary {
    background-color: white;
    color: var(--color-primary);
  }
} 