.st-jobs {
  &-container {
    min-height: 100vh;
    background-color: #f9fafb; // bg-gray-50
  }

  &-main {
    // max-width: 1280px; // container
    margin: 0 auto;
    padding: 2rem 1rem; // px-4 py-8
  }

  &-content {
    display: flex;
    gap: 2rem; // gap-8

    @media screen and (max-width:700px){
      flex-direction: column-reverse;
    }
  }

  &-results {
    flex: 1;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.5rem; // mb-6
    }

    &-count {
      font-size: 0.875rem; // text-sm
      color: #6b7280; // text-muted-foreground
    }

    &-sort {
      display: flex;
      align-items: center;
      gap: 0.5rem; // gap-2

      span {
        font-size: 0.875rem;
        color: #6b7280;
      }

      select {
        width: 150px;
        border-radius: 0.375rem; // rounded-md
        border: 1px solid #e5e7eb; // border-input
        background-color: #fff; // bg-background
        padding: 0.25rem 0.75rem; // px-3 py-1
        font-size: 0.875rem;
      }
    }
  }
} 