.st-book-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 8px;
  outline: none;
  padding: 20px;
  max-width: 90%;
  width: 900px;
  max-height: 90vh;
  overflow-y: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.st-book-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.st-book-modal-content {
  position: relative;
  text-align: center;
}

.st-modal-close {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  font-size: 32px;
  cursor: pointer;
  padding: 5px 15px;
  z-index: 1;
  line-height: 1;
  font-weight: bold;
  transition: opacity 0.2s ease;
}

.st-modal-close:hover {
  opacity: 0.7;
}

.st-modal-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
  margin: 0 auto;
}

.st-modal-title {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}

.st-modal-price {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
}

.st-modal-description {
  margin-bottom: 20px;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .st-book-modal {
    padding: 15px;
    width: 95%;
  }

  .st-modal-title {
    font-size: 20px;
  }

  .st-modal-price {
    font-size: 18px;
  }

  .st-modal-description {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .st-book-modal {
    padding: 10px;
  }

  .st-modal-close {
    font-size: 28px;
    padding: 5px 12px;
  }
}
